<template>
  <div class="min-h-screen">
    <alert />

    <modal />
    <mobile-sidebar />
    <desktop-sidebar />
    <div class="lg:pl-64 flex flex-col">
      <main class="flex-1">
        <top />
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import Top from '@/components/Top'
import Modal from '@/components/Modal'
import Alert from '@/components/Alert'

import MobileSidebar from '@/components/MobileSidebar'
import DesktopSidebar from '@/components/DesktopSidebar'

export default {
  name: 'app',
  components: {
    Top,
    Modal,
    Alert,
    MobileSidebar,
    DesktopSidebar,
  },
  data() {
    return {}
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {},
  methods: {
    init() {},
    logout() {},
  },
}
</script>
