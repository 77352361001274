<template>
  <div
    class="hidden lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-5 lg:pb-4 lg:bg-gray-100"
  >
    <div class="flex items-center flex-shrink-0 px-6">
      <img class="h-8 w-auto" src="/images/logo.png" alt="OFG" />
    </div>
    <div class="mt-6 h-0 flex-1 flex flex-col overflow-y-auto">
      <div v-away="close" class="px-3 relative inline-block text-left">
        <div>
          <button
            type="button"
            class="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none"
            id="options-menu-button"
            aria-expanded="false"
            aria-haspopup="true"
            @click.prevent="context = !context"
          >
            <span class="flex w-full justify-between items-center">
              <span class="flex min-w-0 items-center justify-between space-x-3">
                <avatar
                  :username="me.name"
                  class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                />
                <span class="flex-1 flex flex-col min-w-0">
                  <span class="text-gray-900 text-sm font-medium truncate">{{ me.name }}</span>
                  <span class="text-gray-500 text-sm truncate">@{{ me.mention }}</span>
                </span>
              </span>
              <svg
                class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </button>
        </div>
        <context-menu
          v-model="context"
          :menu="menu"
          class-name="z-10 mx-3 origin-top absolute sm:w-64 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
        />
      </div>

      <nav class="px-3 mt-6">
        <div class="space-y-1">
          <router-link
            v-if="can('list_instances')"
            to="/"
            class="text-gray-700 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
            aria-current="page"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              class="h-4 w-4 mr-2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"
              />
            </svg>
            Instances
          </router-link>

          <router-link
            v-if="can('list_users')"
            to="/users"
            class="text-gray-700 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              class="h-4 w-4 mr-2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
            Users
          </router-link>

          <router-link
            v-if="can('list_groups')"
            to="/groups-and-permissions"
            class="text-gray-700 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              class="h-4 w-4 mr-2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            Groups &amp; Permissions
          </router-link>
        </div>
        <!-- <div class="mt-8">
          <h3
            class="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
            id="desktop-teams-headline"
          >
            Teams
          </h3>
          <div class="mt-1 space-y-1" role="group" aria-labelledby="desktop-teams-headline">
            <a
              href="#"
              class="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
            >
              <span class="w-2.5 h-2.5 mr-4 bg-indigo-500 rounded-full" aria-hidden="true"></span>
              <span class="truncate"> Engineering </span>
            </a>

            <a
              href="#"
              class="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
            >
              <span class="w-2.5 h-2.5 mr-4 bg-green-500 rounded-full" aria-hidden="true"></span>
              <span class="truncate"> Human Resources </span>
            </a>

            <a
              href="#"
              class="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
            >
              <span class="w-2.5 h-2.5 mr-4 bg-yellow-500 rounded-full" aria-hidden="true"></span>
              <span class="truncate"> Customer Success </span>
            </a>
          </div>
        </div> -->
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesktopSidebar',
  data() {
    return {
      context: false,
      menu: [
        {
          items: [
            {
              label: 'Home',
              slug: 'home',
              to: '/',
            },
            {
              label: 'Settings',
              slug: 'settings',
              to: '/settings',
              permission: 'settings',
            },
          ],
        },
        {
          items: [
            {
              label: 'Logout',
              slug: 'logout',
              to: '/',
              callback: 'logout',
            },
          ],
        },
      ],
    }
  },
  methods: {
    close() {
      this.context = false
    },
  },
}
</script>

<style></style>
