<template>
  <nav class="bg-white shadow">
    <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div
          class="sticky top-0 -ml-4 sm:-ml-6 lg:-ml-8 z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden"
        >
          <!-- Sidebar toggle, controls the 'sidebarOpen' sidebar state. -->
          <button
            type="button"
            class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none lg:hidden"
            @click.prevent="setSidebar(true)"
          >
            <span class="sr-only">Open sidebar</span>
            <!-- Heroicon name: outline/menu-alt-1 -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </button>
        </div>
        <div class="hidden md:flex">&nbsp;</div>
        <div class="flex items-center">
          <div
            v-if="newButton.label && can(`add_${newButton.label.toLowerCase()}`)"
            class="flex-shrink-0"
          >
            <button
              type="button"
              class="relative inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded text-white bg-indigo-800 shadow-sm hover:bg-indigo-700"
              @click.prevent="
                setModal({
                  show: true,
                  component: newButton.component,
                  notifier: notifier[newButton.label.toLowerCase()],
                })
              "
            >
              <!-- Heroicon name: solid/plus-sm -->
              <svg
                class="-ml-1 mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>New {{ newButton.label }}</span>
            </button>
          </div>

          <div class="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
            <!-- Profile dropdown -->
            <div v-away="close" class="ml-3 relative z-40">
              <div>
                <button
                  type="button"
                  class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  id="user-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                  @click.prevent="context = !context"
                >
                  <span class="sr-only">Open user menu</span>
                  <avatar :username="me.name" :size="40" />
                </button>
              </div>

              <context-menu
                v-model="context"
                :menu="menu"
                class-name="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="md:hidden">
      <div class="pt-4 pb-3 border-t border-gray-200">
        <div class="flex items-center px-4 sm:px-6">
          <a href="#" v-away="closeMobile" class="flex-shrink-0" @click.prevent="mobile = !mobile">
            <avatar :username="me.name" :size="40" />
          </a>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">{{ me.name }}</div>
            <div class="text-sm font-medium text-gray-500">{{ me.mention }}</div>
          </div>
        </div>
        <context-menu
          v-model="mobile"
          :menu="menu"
          class-name="origin-top-left absolute left-0 mt-2 w-48 z-50 rounded shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        />
      </div>
    </div>
  </nav>
</template>

<script>
import { logoutUser } from '@/utils/auth'

export default {
  name: 'Top',
  props: [],
  data() {
    return {
      context: false,
      mobile: false,
      notifier: {
        group: 'reload-groups',
        user: 'reload-data-table',
        instance: 'reload-data-table',
      },
      menu: [
        {
          items: [
            {
              label: 'Home',
              slug: 'home',
              to: '/',
            },
            {
              label: 'Settings',
              slug: 'settings',
              to: '/settings',
              permission: 'settings',
            },
          ],
        },
        {
          items: [
            {
              label: 'Logout',
              slug: 'logout',
              callback: () => {
                logoutUser()
                this.$router.push('/signin')
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    close() {
      this.context = false
    },
    closeMobile() {
      this.mobile = false
    },
  },
}
</script>

<style></style>
