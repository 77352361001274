<template>
  <div>
    <input type="file" id="fileSelector" class="hidden" />
    <data-table :columns="columns" endpoint="instances" ref="dt">
      <template slot-scope="{ row, index }">
        <td class="px-6 py-1 text-sm font-medium text-gray-900">
          <a
            :href="row.updatedAt ? `https://${row.sslCertificateInstalled || row.fqdn}` : '#'"
            :target="row.updatedAt ? '_blank' : ''"
            class="truncate hover:text-gray-600"
          >
            <span class="flex items-center space-x-1">
              <img
                v-if="row.status"
                class="rounded-full w-3 h-3"
                :src="`/images/led-${row.status}.gif`"
              />
              <a href="#" @click.prevent="getStatus(row.id, index)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </a>
              <span class="flex items-end space-x-1">
                <span class="text-md">{{ row.name }}</span>
                <span class="text-gray-500 text-xs font-normal">{{ row.type }}</span>
              </span>
            </span>
          </a>
        </td>
        <td
          class="hidden md:table-cell px-6 py-1 whitespace-nowrap text-sm text-gray-500 text-left"
        >
          <span
            v-if="row.status === 'green' && row.fqdn"
            :title="row.sslCertificateInstalled || row.fqdn"
          >
            <a
              class="rowLink"
              :href="'https://' + (row.sslCertificateInstalled || row.fqdn)"
              target="_blank"
            >
              {{ row.sslCertificateInstalled || row.fqdn }}
            </a>
          </span>
          <span v-else-if="row.fqdn" :title="row.sslCertificateInstalled || row.fqdn">
            {{ row.sslCertificateInstalled || row.fqdn }}
          </span>
          <span v-else>
            <img src="/images/led-loading.gif" title="domain is being assigned" />
          </span>
        </td>
        <td
          v-if="row.ip"
          class="hidden md:table-cell px-6 py-1 whitespace-nowrap text-sm text-gray-500 text-left"
        >
          {{ row.ip }}
        </td>
        <td
          v-if="row.deployedAt"
          class="hidden md:table-cell px-6 py-1 whitespace-nowrap text-sm text-gray-500 text-left"
        >
          <span v-if="row.error" :title="row.error"> &#x26A0;&#xFE0F; </span>
          <span v-else-if="row.deployedAt" v-html="dateOrDash(row.updatedAt || row.deployedAt)" />
          <!-- <span v-else>
            <img
              src="/images/led-loading.gif"
              title="instance not deployed and / or being deployed"
            />
          </span> -->
        </td>
        <td class="px-6 py-1 whitespace-nowrap text-center text-sm font-medium">
          <data-table-row-actions
            @event="event"
            :id="row.id"
            :iid="row.iid"
            :index="index"
            :actions="actions"
          />
        </td>
      </template>
    </data-table>
  </div>
</template>

<script>
import http from '@/utils/http'

export default {
  name: 'Dashboard',
  props: [],
  components: {
    /* DesktopStats, MobileStats, */
  },
  data() {
    return {
      instances: [],
      columns: [],
      stats: [
        {
          short: 'OK',
          label: 'Working Instances',
          class: 'bg-green-600',
          count: 'Total Working 145',
        },
        {
          short: 'WA',
          label: 'Upgradable Instances',
          class: 'bg-yellow-400',
          count: '32 needed upgrade',
        },
        {
          short: 'ER',
          label: 'Broken Instances',
          class: 'bg-red-600',
          count: '12 with error',
        },
        {
          short: 'NE',
          label: 'New Instances',
          class: 'bg-indigo-600',
          count: '2 newly created',
        },
      ],
      actions: [
        {
          label: 'Update Domain',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" /></svg>`,
          event: 'alterDomain',
          permission: 'edit_domain',
        },
        {
          label: 'Update Instance',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" /></svg>`,
          event: 'updateInstance',
          permission: 'edit_instance',
        },
        {
          label: 'Import Assets',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" /></svg>`,
          event: 'importAssets',
          permission: 'import_assets',
        },
        {
          label: 'Delete Instance',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>`,
          event: 'deleteInstance',
          permission: 'delete_instance',
        },
        {
          label: 'Instance Log',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" /></svg>`,
          event: 'readLog',
          permission: 'log_instance',
        },
      ],
      showLog: [],
      updating: false,
      action: '',
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.$bus.$off('reload-table-row', this.getStatus)
  },
  methods: {
    init() {
      this.setNewButton({
        label: 'Instance',
        component: 'new-instance',
        endpoint: 'instances',
        notifier: 'reload-data-table',
      })

      this.columns = this.me.super
        ? [
            ...this.columns,
            {
              label: '',
              width: '5%',
              align: 'text-left',
              type: 'check',
            },
            {
              label: 'Name',
              width: '25%',
              align: 'text-left',
              span: 2,
            },
            {
              label: 'Domain',
              width: '15%',
              align: 'text-left',
            },
            {
              label: 'IP Address',
              width: '15%',
              align: 'text-left',
            },
            {
              label: 'Last Updated',
              width: '10%',
              align: 'text-center',
            },
            {
              label: '',
              width: '30%',
            },
          ]
        : [
            {
              label: '',
              width: '5%',
              align: 'text-left',
              type: 'check',
            },
            {
              label: 'Name',
              width: '25%',
              align: 'text-left',
              span: 2,
            },
            {
              label: 'Domain',
              width: '15%',
              align: 'text-left',
            },
            {
              label: '',
              width: '30%',
            },
          ]
      this.setPage({ title: 'Dashboard' })

      this.$bus.$on('reload-table-row', payload => this.getStatus(payload.id, payload.index))
    },
    readLog(instance) {
      http.get(`/instances/${instance.id}/log`)
    },
    getStatus(instanceId, index) {
      http.get(`/instances/${instanceId}/status`).then(response => {
        this.$refs.dt.rows[index].status = response.data.status

        this.$forceUpdate()
      })
    },
    syncInstanceAndDns() {
      http.get('/records/sync').then(() => {
        this.getData()
      })
    },
    async alterDomain(instance) {
      this.setModal({
        label: 'Update Domain Name',
        show: true,
        component: 'fqdn',
        notifier: 'reload-table-row',
        endpoint: `instances/${instance.id}/fqdn`,
        id: instance.id,
        index: instance.index,
      })
    },
    updateInstance(instance) {
      try {
        this.$refs.dt.rows[instance.index].status = 'loading'

        http
          .put(`/instances?ids=${instance.id}`)
          .then(() => this.getStatus(instance.id, instance.index))
      } catch (x) {
        console.error(x)
        alert('Error')
      }
    },
    importAssets(instance) {
      let fileSelector = document.getElementById('fileSelector')
      fileSelector.click()

      fileSelector.onchange = async function () {
        let form = new FormData(),
          file = fileSelector.files[0]
        form.append('file', file)

        http.post(`/instances/${instance.id}/asset`, form).catch(x => {
          console.error(x)
          // alert('Error importing file ' + file.name + ' into instance ' + instanceId)
        })

        // alert('Started importing asset metadata. This may take a while...')
        fileSelector.value = null
      }
    },
    deleteInstance(instance) {
      this.setModal({
        show: true,
        component: 'delete',
        data: {
          id: instance.id,
        },
        notifier: 'reload-data-table',
      })
    },
    event(payload) {
      this[payload.callback](payload)
    },
  },
}
</script>

<style></style>
